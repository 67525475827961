import React, { useRef } from 'react';
import {
  Category,
  DLPCarouselData,
} from '../../../types/DynamicLandingPageReducer';
import { useIsInViewOnScroll } from '../../../components/useInViewOnScroll';
import events, { EVENTS } from '../../../utilities/segment/events';
import { ReactSpringCenterCarousel } from '../../ui/ReactSpringCenterCarousel';
import {
  CATEGORY_ACCESSORIES_ID,
  CATEGORY_HANDBAG_STYLES_ID,
  CATEGORY_JEWELRY_ID,
  CATEGORY_WATCHES_ID,
  NOT_SEARCHABLE_INDEX,
} from '../../../utilities/constants';

const DLPCarousel = ({
  data,
}: {
  data: DLPCarouselData;
}): React.ReactElement => {
  const productData = data?.fields;
  const carouselRef = useRef<HTMLDivElement>(null);

  const getTopLevelCategory = (categories: Category[]): string => {
    const [first] = categories.filter(
      (c) =>
        [
          CATEGORY_HANDBAG_STYLES_ID,
          CATEGORY_JEWELRY_ID,
          CATEGORY_ACCESSORIES_ID,
          CATEGORY_WATCHES_ID,
        ].indexOf(c.id) > NOT_SEARCHABLE_INDEX,
    );
    if (first?.name?.match(/Accessories|Watches|Jewelry/)) {
      return first.name;
    }
    return 'Bags';
  };

  const productDataWithCategory = productData?.productData.map(
    (item) => {
      const category = getTopLevelCategory(item.categories);
      return { ...item, category };
    },
  );

  useIsInViewOnScroll(carouselRef.current, {
    fireOnce: true,
    callBack: (inView: boolean) => {
      if (inView) {
        events.emit(EVENTS.productListViewed, {
          listType: 'Featured Items',
          title: productData?.header,
          productData: productDataWithCategory || [],
        });
      }
    },
  });

  if (!productData || !productData?.productData?.length) {
    return <></>;
  }

  return (
    <div className="homepageCarousel" ref={carouselRef}>
      <ReactSpringCenterCarousel
        products={productData.productData}
        carouselTitle={productData.header}
        variant="center"
      />
    </div>
  );
};

export default DLPCarousel;
