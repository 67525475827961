import React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { LinkButton } from '@fashionphile/component-library';
import { ShopState } from '../../../types/Store';
import {
  convertButtonString,
  contentfulSrcSet,
  sanitizeImageUrl,
} from '../../../utilities/helpers';
import {
  MOBILE_PORTRAIT_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../utilities/constants';
import {
  ImagePlusTextData,
  TypeCopyBlock,
  ImageData,
} from '../../../types/DynamicLandingPageReducer';
import { renderRichText } from '../utils';

let targetAttribValue = '';

const RenderImage = ({
  imageAltCopy,
  imageLink,
  index,
  linkUrl = '',
  targetAttributeValue,
}: ImageData): React.ReactElement => {
  // remove trailing forward slash so that I can grab the last bit of the url to make a title
  if (linkUrl[linkUrl.length - 1] === '/') {
    linkUrl = linkUrl.slice(0, linkUrl.length - 1);
  }
  const linkTitle = linkUrl.split('/')[linkUrl.split('/').length - 1];
  const sanitizedImageUrl = sanitizeImageUrl(imageLink?.url || '');

  if (linkUrl !== '') {
    return (
      <Link
        href={linkUrl}
        passHref
        target={targetAttributeValue}
        title={`${linkTitle} link`}
      >
        <img
          src={sanitizedImageUrl}
          srcSet={contentfulSrcSet(sanitizedImageUrl, {
            sm: 577,
            md: 769,
            lg: 993,
            xl: 1281,
            xxl: 1600,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
        />
      </Link>
    );
  }
  return (
    <img
      src={sanitizedImageUrl}
      srcSet={contentfulSrcSet(sanitizedImageUrl, {
        sm: 577,
        md: 769,
        lg: 993,
        xl: 1281,
        xxl: 1600,
      })}
      sizes="100vw"
      alt={imageAltCopy}
      loading={index > 0 ? 'lazy' : undefined}
    />
  );
};

const ShowTextBlock = ({
  cta,
  headline,
  linkUrl,
  subCopyRichText,
  targetAttributeValue,
}: TypeCopyBlock): React.ReactElement => {
  targetAttribValue = targetAttributeValue;
  return (
    <>
      <p className="headln" data-testid="hero-header">
        {headline}
      </p>
      {subCopyRichText && (
        <div>
          {subCopyRichText &&
            renderRichText(subCopyRichText, targetAttribValue)}
        </div>
      )}

      {cta && (
        <div className="btn">
          <LinkButton
            target={targetAttributeValue}
            content={cta.textToDisplay}
            size="lg"
            variant={convertButtonString(cta?.color)}
            ariaLabel="button"
            href={linkUrl}
            tabIndex={0}
          />
        </div>
      )}
    </>
  );
};

const ImagePlusText = ({
  data,
  index,
}: {
  data: ImagePlusTextData;
  index: number;
}): React.ReactElement => {
  const cta = data.fields.cta?.fields;
  const linkUrl = cta?.linkUrl || '';
  const imageDetail = data.fields.image?.fields?.file;
  const headline = data.fields.headline || '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const imageAlignment = (
    data.fields.imageAlignment || ''
  ).toLowerCase();
  const imageAltCopy =
    data.fields?.imageAltCopy || 'promotional banner';
  const imageProportion = data.fields?.imageProportion || '';
  const subCopyRichText = data.fields?.subcopyRichText;
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  const width = useSelector(
    (state: ShopState) => state.headerFooterReducer.width,
  );

  const tabletView =
    !!width &&
    width > MOBILE_PORTRAIT_BREAKPOINT &&
    width < TABLET_BREAKPOINT;
  let colsForImage = 'col-4';
  let colsForTextBlock = 'col-8';
  const isImageLongTabletView =
    imageProportion === '66%' && tabletView;
  if (imageProportion === '50%') {
    colsForImage = 'col-6';
    colsForTextBlock = 'col-6';
  }
  if (imageProportion === '66%') {
    colsForImage = isImageLongTabletView ? 'col-10' : 'col-8';
    colsForTextBlock = isImageLongTabletView ? 'col-10' : 'col-4';
  }
  const placeHolder = isImageLongTabletView ? (
    <div className="col-md-1" />
  ) : null;
  const imageStyles = classNames(
    colsForImage,
    'imageStyles',
    'col-xs-12',
  );
  const textBlockStyles = classNames(
    colsForTextBlock,
    'textBlockStyles',
    isImageLongTabletView && 'tabletStyles',
    'col-xs-12',
  );
  const showImageFirst =
    imageAlignment === 'left' ||
    isImageLongTabletView ||
    (!!width && width <= MOBILE_PORTRAIT_BREAKPOINT);

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="imagePlusTextContainer">
        {showImageFirst ? (
          <div className="row imagePlusTextRow">
            {placeHolder}
            <div className={imageStyles}>
              <RenderImage
                imageAltCopy={imageAltCopy}
                imageLink={imageDetail}
                index={index}
                linkUrl={linkUrl}
                targetAttributeValue={targetAttributeValue}
              />
            </div>
            {placeHolder}
            {placeHolder}
            <div className={textBlockStyles}>
              <ShowTextBlock
                cta={cta}
                headline={headline}
                linkUrl={linkUrl}
                subCopyRichText={subCopyRichText}
                targetAttributeValue={targetAttributeValue}
              />
            </div>

            {placeHolder}
          </div>
        ) : (
          <div className="row imagePlusTextRow">
            {placeHolder}
            <div className={textBlockStyles}>
              <ShowTextBlock
                cta={cta}
                headline={headline}
                linkUrl={linkUrl}
                subCopyRichText={subCopyRichText}
                targetAttributeValue={targetAttributeValue}
              />
            </div>

            {placeHolder}
            {placeHolder}
            <div className={imageStyles}>
              <RenderImage
                imageAltCopy={imageAltCopy}
                imageLink={imageDetail}
                index={index}
                linkUrl={linkUrl}
                targetAttributeValue={targetAttributeValue}
              />
            </div>
            {placeHolder}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagePlusText;
