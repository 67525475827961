import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { contentfulSrcSet } from '../../../utilities/helpers';
import { useIsInViewOnScroll } from '../../useInViewOnScroll';
import events, { EVENTS } from '../../../utilities/segment/events';
import type { ShopState } from '../../../types/Store';
import { HeroBannerData } from '../../../types/DynamicLandingPageReducer';
import { renderRichText } from '../utils';

const hexToRgb = (hex: string): string => {
  hex = hex?.replace(/^\s*#|\s*$/g, '');

  const FIRST_HEX_INDEX = 0;
  const THIRD_HEX_INDEX = 2;
  const FOURTH_HEX_INDEX = 4;
  const SIXTH_HEX_INDEX = 6;
  const BASE_RADIX = 16;

  const r = parseInt(
    hex?.slice(FIRST_HEX_INDEX, THIRD_HEX_INDEX),
    BASE_RADIX,
  );
  const g = parseInt(
    hex?.slice(THIRD_HEX_INDEX, FOURTH_HEX_INDEX),
    BASE_RADIX,
  );
  const b = parseInt(
    hex?.slice(FOURTH_HEX_INDEX, SIXTH_HEX_INDEX),
    BASE_RADIX,
  );

  // Return the RGB values
  return `rgb(${r || '255'},${g || '255'},${b || '255'})`;
};

const HeroBannerHalfHeight = ({
  data,
}: {
  data: HeroBannerData;
}): React.ReactElement => {
  const router = useRouter();
  const [isFocus, setIsFocus] = useState(false);
  const width = useSelector(
    (state: ShopState) => state.headerFooterReducer.width,
  );

  const isAnimation = data.fields?.useAnimationBackground || '';
  const linearGradientBackground =
    data.fields?.animationBackgroundColors || '';
  const directionValue = linearGradientBackground.split(',')[0] || '';
  const hexColorValues =
    linearGradientBackground?.split(', ')?.slice(1) || [];
  const rgbValuesCombined = hexColorValues.reduce(
    (accumulator, currentValue, currentIndex) =>
      accumulator +
      hexToRgb(currentValue) +
      (currentIndex === hexColorValues.length - 1 ? '' : ', '),
    '',
  );
  const fineprint = data.fields?.fineprintCta || '';
  const richTextCopy = data.fields?.richTextCopy || '';
  const richTextColor =
    data.fields?.textColor?.toLowerCase() || 'black';
  const richTextStyles = `${richTextColor}-desktopText`;
  const richTextAlignment =
    data.fields?.richTextAlignment?.toLowerCase() || '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const title = data.fields?.title || '';
  const backgroundColor = data.fields?.backgroundColor || 'ffffff';
  const heroImageLink = data.fields?.heroImageLink || router.asPath;
  const desktopImageUrl =
    data.fields?.desktopImage?.fields?.file?.url || '';
  const mobileImageUrl =
    data.fields?.mobileImage?.fields?.file?.url || '';
  const imageAltCopy = data.fields?.imageAltCopy || '';

  const ref = useRef<HTMLDivElement>(null);
  const animationBgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationBgRef?.current) {
      animationBgRef.current.style.backgroundImage = `linear-gradient(${directionValue},${rgbValuesCombined})`;
    }
  }, [animationBgRef]);

  useIsInViewOnScroll(ref.current, {
    init: true,
    callBack: (state: boolean) => {
      if (state) {
        events.emit(EVENTS.promoViewed, {
          creative: null,
          name: title,
          position: 'top of homepage',
          promotion_id: 0,
        });
      }
    },
  });

  let mobileImagesrcSet;
  let desktopImagesrcSet;
  desktopImagesrcSet = contentfulSrcSet(desktopImageUrl, {
    sm: 577,
    md: 769,
    lg: 993,
    xl: 1281,
    xxl: 1600,
  });
  mobileImagesrcSet = contentfulSrcSet(mobileImageUrl, {
    sm: 577,
    md: 769,
    lg: 993,
    xl: 1281,
    xxl: 1600,
  });

  const handleFocusVisible = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
  ): void => {
    if (event.key === 'Tab') {
      setIsFocus(true);
    }
  };
  const MAX_SCREEN_WIDTH = 1800;
  const isMaxScreenWidth = width !== null && width > MAX_SCREEN_WIDTH;

  return (
    <div
      style={{ backgroundColor: `#${backgroundColor}` }}
      className={classNames(
        'heroBannerHalfHeightContainer',
        isMaxScreenWidth && 'applyStyles',
        isFocus && 'focus',
      )}
      ref={ref}
    >
      <div
        className={`heroWrapper ${isAnimation ? 'fullWidth' : ''}`}
      >
        <Link
          className="heroBannerLink"
          href={heroImageLink}
          onBlur={(): void => {
            setIsFocus(false);
          }}
          onKeyUp={handleFocusVisible}
          target={targetAttributeValue}
          title={heroImageLink}
        >
          {!isAnimation && desktopImageUrl && mobileImageUrl && (
            <>
              <img
                srcSet={desktopImagesrcSet}
                alt={imageAltCopy as string}
                data-testid="hero-banner-half-height-image"
                loading="eager"
                src={desktopImageUrl}
                className="desktopOnly"
              />
              <img
                srcSet={mobileImagesrcSet}
                alt={imageAltCopy as string}
                data-testid="hero-banner-half-height-image"
                loading="eager"
                src={mobileImageUrl}
                className="mobileOnly"
              />
            </>
          )}
          {isAnimation && (
            <div
              ref={animationBgRef}
              className="animationBackground"
            ></div>
          )}
        </Link>
      </div>

      {richTextCopy && (
        <div
          className={`richTextCopyWrapper ${richTextAlignment} ${richTextStyles}`}
        >
          {renderRichText(
            richTextCopy,
            targetAttributeValue,
            '',
            richTextStyles,
          )}

          {fineprint && (
            <div className="fineprint">
              {renderRichText(fineprint, '', '', richTextStyles)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeroBannerHalfHeight;
