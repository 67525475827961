import React from 'react';
import { HighlightsData } from '../../../types/DynamicLandingPageReducer';

const Highlights = ({
  data,
  index,
}: {
  data: HighlightsData;
  index: number;
}): React.ReactElement => {
  const headline = data.fields.headline || '';
  const highlightTiles = data.fields.highlightTiles || [];
  const tileOptions = data.fields.tileOptions || '';
  const isOneColumn = tileOptions.includes('1');
  const isTwoColumns = tileOptions.includes('2');
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  const backgroundColor = `${(
    data.fields.backgroundColor || 'white'
  ).toLowerCase()}-bg`;

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className={`highlightsContainer ${backgroundColor}`}>
        {headline && (
          <div className="row">
            <h2 className="col-12 headln">{headline}</h2>
          </div>
        )}
        {isOneColumn && (
          <div className="row">
            {highlightTiles.map((tile, idx) => {
              const subHeadline = tile.fields.subHeadline || '';
              const subCopy = tile.fields.subcopy || '';
              const imageDetails =
                tile?.fields?.tileImage?.fields?.file;
              return (
                <React.Fragment key={`${subHeadline}-${idx}`}>
                  <div className="col-1 placeholder" />
                  <div className="col-1">
                    {imageDetails.url && (
                      <img
                        src={`https:${imageDetails.url}`}
                        alt="Highlights"
                      />
                    )}
                  </div>
                  <div className="col-9 col-xs-11 textBlock">
                    <h2
                      className="subHeadln"
                      data-testid="highlightsTileSubHeadline"
                    >
                      {subHeadline}
                    </h2>
                    {subCopy && (
                      <p
                        className="subCopy"
                        data-testid="highlightsTileSubCopy"
                      >
                        {subCopy}
                      </p>
                    )}
                  </div>
                  <div className="col-1 placeholder" />
                </React.Fragment>
              );
            })}
          </div>
        )}

        {isTwoColumns && (
          <div className="row">
            {highlightTiles.map((tile, idx) => {
              const subHeadline = tile.fields?.subHeadline || '';

              const subCopy = tile.fields?.subcopy || '';
              const imageDetails =
                tile.fields.tileImage?.fields?.file || '';
              return (
                <React.Fragment key={`${subHeadline}-${idx}`}>
                  <div className="col-1">
                    {imageDetails.url && (
                      <img
                        src={`https:${imageDetails.url}`}
                        alt="Highlights"
                      />
                    )}
                  </div>
                  <div className="col-5 col-xs-11 textBlock">
                    <h2
                      className="subHeadln"
                      data-testid="highlightsTileSubHeadline"
                    >
                      {subHeadline}
                    </h2>
                    {subCopy && (
                      <p
                        className="subCopy"
                        data-testid="highlightsTileSubCopy"
                      >
                        {subCopy}
                      </p>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Highlights;
