import React from 'react';
import Link from 'next/link';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node, BLOCKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { contentfulSrcSet } from '../../../utilities/helpers';
import {
  GiftGuideData,
  ImageData,
} from '../../../types/DynamicLandingPageReducer';

const RenderImage = ({
  imageAltCopy,
  imageLink,
  index,
  linkUrl = '',
  targetAttributeValue,
}: ImageData): React.ReactElement => {
  // remove trailing forward slash so that I can grab the last bit of the url to make a title
  if (linkUrl[linkUrl.length - 1] === '/') {
    linkUrl = linkUrl.slice(0, linkUrl.length - 1);
  }
  const linkTitle = linkUrl.split('/')[linkUrl.split('/').length - 1];

  if (linkUrl !== '') {
    return (
      <Link
        href={linkUrl}
        passHref
        target={targetAttributeValue}
        title={`${linkTitle} link`}
      >
        <img
          src={`https://${imageLink?.url}`}
          srcSet={contentfulSrcSet(imageLink?.url || '', {
            sm: 577,
            md: 769,
            lg: 993,
            xl: 1281,
            xxl: 1600,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
        />
      </Link>
    );
  }

  return (
    <img
      src={`https://${imageLink?.url}`}
      srcSet={contentfulSrcSet(imageLink?.url || '', {
        sm: 529,
        md: 349,
        lg: 453,
        xl: 596,
        xxl: 596,
      })}
      sizes="100vw"
      alt={imageAltCopy}
      loading={index > 0 ? 'lazy' : undefined}
      tabIndex={0}
    />
  );
};

const GiftGuide = ({
  data,
  index,
}: {
  data: GiftGuideData;
  index: number;
}): React.ReactElement => {
  const headline = data.fields.headline || '';
  const textAlignment = (
    data.fields.textAlignment || ''
  ).toLowerCase();
  const giftGuideImage = data.fields.giftGuideImage || [];
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';

  const untaggedCopyOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => children,
      [INLINES.HYPERLINK]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => children,
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="giftGuideContainer">
        {headline && (
          <div className="row">
            <h2 className="col-12 headln">{headline}</h2>
          </div>
        )}
        <div className="row">
          {giftGuideImage.map((imageDetail, idx) => {
            const linkUrl: string =
              imageDetail.fields.link1?.content?.[0].content?.[1].data
                ?.uri || '';
            const imageDetails =
              imageDetail.fields.tileImage?.fields?.file || '';
            const link1 = imageDetail.fields?.link1 || '';
            const hrefValue =
              link1?.content?.[0]?.content?.[1]?.data?.uri || '';
            const imageAltCopy =
              imageDetail.fields?.imageAltCopy ||
              'promotional banner';

            return (
              <div
                key={idx}
                className={classNames(
                  textAlignment,
                  'col-3',
                  'col-xs-6',
                )}
              >
                <div className="imageStyles">
                  <RenderImage
                    imageAltCopy={imageAltCopy}
                    imageLink={imageDetails}
                    index={index}
                    linkUrl={linkUrl}
                    targetAttributeValue={targetAttributeValue}
                  />
                </div>

                {link1 && (
                  <h3>
                    <a
                      className="hyperLink"
                      href={hrefValue}
                      target={targetAttributeValue}
                      rel="noreferrer"
                      tabIndex={-1}
                    >
                      {documentToReactComponents(
                        link1,
                        untaggedCopyOptions as Options,
                      )}
                    </a>
                  </h3>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GiftGuide;
