import React from 'react';

const Video = (): React.ReactElement => {
  // TODO: SET TAB ORDER
  return (
    <div className="row video">
      <div className="col-12">Content block 3 - Video</div>
    </div>
  );
};

export default Video;
