import React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  Node,
  MARKS,
  Document,
} from '@contentful/rich-text-types';
import { Bold } from '../contentful/text';

const renderRichText = (
  content: Document,
  targetAttributeValue = '_self',
  h1Styles = '',
  hyperLinkStyles = '',
): React.ReactElement => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text: React.ReactElement,
      ): React.ReactElement => <Bold className="bold">{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h2 className={h1Styles}>{children}</h2>
      ),
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <p>{children}</p>,
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className={`hyperLink ${hyperLinkStyles}`}
          href={node.data?.uri || ''}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
      [BLOCKS.HEADING_2]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h3>{children}</h3>,
      [BLOCKS.OL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ol className="olStyles">{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ul className="ulStyles">{children}</ul>;
      },
    },
  };

  return (
    <>{documentToReactComponents(content, options as Options)}</>
  );
};

export { renderRichText };
