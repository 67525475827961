import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import useEventListener from './useEventListener';
import {
  DEFAULT_MILLISECONDS_WAIT_DEBOUNCE,
  FAR_LEFT,
  HALF_DIVIDER,
  TOP_OF_PAGE,
} from '../utilities/constants';

export const isInView = (
  el: HTMLElement | null,
  target: 'bounds' | 'center' = 'center',
): boolean => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    !el
  ) {
    return false;
  }

  const rect = el.getBoundingClientRect();
  const { top, right, bottom, left } = rect;
  const yMax =
    window.innerHeight || document.documentElement.clientHeight;
  const xMax =
    window.innerWidth || document.documentElement.clientWidth;

  if (target === 'bounds') {
    return (
      top >= TOP_OF_PAGE &&
      left >= FAR_LEFT &&
      bottom <= yMax &&
      right <= xMax
    );
  }
  if (target === 'center') {
    const centerY = (top + bottom) / HALF_DIVIDER;
    const centerX = (left + right) / HALF_DIVIDER;
    return (
      centerY > TOP_OF_PAGE &&
      centerY <= yMax &&
      centerX > FAR_LEFT &&
      centerX <= xMax
    );
  }
  return false;
};

/**
 * Provides scroll monitoring for 'target'
 *
 * Can handle a callback that triggers on state change
 */
export const useIsInViewOnScroll = (
  target: HTMLElement | null,
  opts: {
    init?: boolean;
    throttleMs?: number;
    callBack?: (state: boolean) => any;
    fireOnce?: boolean;
  },
): boolean => {
  const {
    init = false,
    throttleMs = DEFAULT_MILLISECONDS_WAIT_DEBOUNCE,
    callBack = null,
    fireOnce = false,
  } = opts || {};
  const [inView, setInView] = useState(init);
  const [hasFired, setHasFired] = useState(false);

  const listener = (): void => {
    if ((fireOnce && hasFired) || !target) {
      return;
    }
    throttle(() => setInView(isInView(target)), throttleMs)();
  };
  useEventListener(
    typeof window === 'undefined' ? true : window,
    'scroll',
    listener,
  );

  useEffect(() => {
    if (fireOnce && hasFired) {
      return;
    }
    callBack?.(inView);
    if (inView && fireOnce && !hasFired) {
      setHasFired(true);
    }
  }, [inView]);

  return target ? inView : false;
};
