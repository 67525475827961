import React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { TextBannerData } from '../../../types/DynamicLandingPageReducer';

const TextBanner = ({
  data,
  index,
}: {
  data: TextBannerData;
  index: number;
}): React.ReactElement => {
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const headline = data.fields.headline || '';
  const subCopy = data.fields.subcopy || '';
  const backgroundColor = (
    data.fields.backgroundColor || ''
  ).toLowerCase();
  const isDarkBg = backgroundColor === 'black';
  const align = (data.fields.textAlignment || '').toLowerCase();
  const link = data.fields.link || '';
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className="hyperLink"
          href={node.data.uri}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className={`textBannerContainer ${align}`}>
        <div
          className={classNames(
            'row',
            'textBannerRow',
            `${backgroundColor}-bg`,
          )}
        >
          <div className="col-1 placeholder" />
          <div
            className={classNames(
              'col-10',
              'col-xs-12',
              'mobileStyles',
              isDarkBg && 'textColor',
            )}
          >
            <p className="headln" data-testid="hero-header">
              {headline}
            </p>
            {subCopy && (
              <p className="subhed" data-testid="hero-subheader">
                {subCopy}
              </p>
            )}
            {link && (
              <div className="fineprintCTA">
                {documentToReactComponents(link, options as Options)}
              </div>
            )}
          </div>
          <div className="col-1 placeholder" />
        </div>
      </div>
    </div>
  );
};

export default TextBanner;
