import React from 'react';
import { IconBoxData } from '../../../types/DynamicLandingPageReducer';
import { renderRichText } from '../utils';
import { HALF_DIVIDER } from '../../../utilities/constants';

const IconBox = ({
  data,
}: {
  data: IconBoxData;
}): React.ReactElement => {
  const MIN_TILES_LENGTH = 2;
  const topHeadline = data.fields?.headline || '';
  const align = (data.fields?.textAlignment || '').toLowerCase();
  const backgroundColor = `${(
    data.fields?.backgroundColor || 'white'
  ).toLowerCase()}-bg`;
  const tiles = data.fields?.tiles || [];
  const eyebrowCopy = data.fields?.eyebrowCopy || '';
  let tileTargetAttributeValue = '';

  return (
    <div className="contentBlockWrapper">
      <div
        className={`iconBoxContainer ${backgroundColor} ${align}`}
        data-testid="iconBoxContainer"
      >
        {eyebrowCopy && (
          <div className="row">
            <div className="col-12 center">{eyebrowCopy}</div>
          </div>
        )}
        {topHeadline && (
          <div className="row">
            <h2 className="col-12 headln center">{topHeadline}</h2>
          </div>
        )}

        <div className="row">
          {tiles.map((item, idx) => {
            const tileBackgroundColor = `${(
              item.fields?.backgroundColor || 'white'
            ).toLowerCase()}-bg`;
            const isEvenNumberOfTiles =
              tiles?.length % HALF_DIVIDER === 0;
            let numberOfCols = isEvenNumberOfTiles
              ? 'col-3'
              : 'col-4';
            if (tiles?.length === MIN_TILES_LENGTH) {
              numberOfCols = 'col-6';
            }
            const tileThumbnailUrl =
              item.fields?.thumbnail?.fields.file.url || null;
            const tileLink = item.fields?.link || '';
            tileTargetAttributeValue = item.fields
              ?.linkOutToAnotherTab
              ? '_blank'
              : '_self';
            const tileSubCopyRichText =
              item.fields?.subCopyRichText || '';
            const tileSubHeadline = item.fields?.subheadline || '';
            const tileHeadline = item.fields?.headline || '';
            const tileThumbnailAltCopy =
              item.fields?.thumbnailAltCopy || '';

            return (
              <div
                key={`${tileSubHeadline}-${idx}`}
                className={`${numberOfCols} col-xs-12 tileRow ${align}  ${tileBackgroundColor}`}
              >
                {tileThumbnailUrl && (
                  <div className="thumbnailContainer">
                    <img
                      src={tileThumbnailUrl}
                      alt={tileThumbnailAltCopy}
                      data-testid="thumbnailTileImage"
                    />
                  </div>
                )}
                {tileHeadline && (
                  <h2 className="subHeadln">{tileHeadline}</h2>
                )}
                {tileSubHeadline && (
                  <h3 className="">{tileSubHeadline}</h3>
                )}
                {tileSubCopyRichText && (
                  <div className="tileSubCopy">
                    {tileSubCopyRichText &&
                      renderRichText(
                        tileSubCopyRichText,
                        tileTargetAttributeValue,
                      )}
                  </div>
                )}
                {tileLink && (
                  <div>
                    {tileLink &&
                      renderRichText(
                        tileLink,
                        tileTargetAttributeValue,
                      )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IconBox;
