import React from 'react';
import Link from 'next/link';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node } from '@contentful/rich-text-types';
import classNames from 'classnames';
import {
  contentfulSrcSet,
  sanitizeImageUrl,
} from '../../../utilities/helpers';
import {
  ImageData,
  ContentTilesData,
  TypeTileFields,
} from '../../../types/DynamicLandingPageReducer';

const RenderImage = ({
  imageAltCopy,
  imageLink,
  index,
  linkUrl = '',
  targetAttributeValue,
}: ImageData): React.ReactElement => {
  // remove trailing forward slash so that I can grab the last bit of the url to make a title
  if (linkUrl[linkUrl.length - 1] === '/') {
    linkUrl = linkUrl.slice(0, linkUrl.length - 1);
  }
  const linkTitle = linkUrl.split('/')[linkUrl.split('/').length - 1];
  const sanitizedImageUrl = sanitizeImageUrl(imageLink?.url || '');

  if (linkUrl !== '') {
    return (
      <Link
        href={linkUrl}
        target={targetAttributeValue}
        title={`${linkTitle} link`}
      >
        <img
          src={sanitizedImageUrl}
          srcSet={contentfulSrcSet(sanitizedImageUrl, {
            sm: 577,
            md: 769,
            lg: 993,
            xl: 1281,
            xxl: 1600,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
        />
      </Link>
    );
  }

  return (
    <img
      src={sanitizedImageUrl}
      srcSet={contentfulSrcSet(sanitizedImageUrl, {
        sm: 577,
        md: 769,
        lg: 993,
        xl: 1281,
        xxl: 1600,
      })}
      sizes="100vw"
      alt={imageAltCopy}
      loading={index > 0 ? 'lazy' : undefined}
      tabIndex={0}
    />
  );
};

const ContentTiles = ({
  data,
  index,
}: {
  data: ContentTilesData;
  index: number;
}): React.ReactElement => {
  const headline = data.fields?.headline || '';
  const arrangement = data.fields?.arrangement || '';
  const isTwoUp = arrangement.includes('2');
  const isThreeUp = arrangement.includes('3');
  const textAlignment = data.fields?.textAlignment || '';
  const isCenterAligned = textAlignment === 'center';
  const contentTiles = data.fields?.contentTiles || [];
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          target={targetAttributeValue}
          className="hyperLink"
          href={node.data.uri}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="contentTilesContainer">
        {headline && (
          <div className="row">
            <h2 className="col-12 headln">{headline}</h2>
          </div>
        )}

        <div className="row mobileStyles">
          {contentTiles.map(
            (content: TypeTileFields, idx: number) => {
              const imageDetails =
                content.fields?.tileImage?.fields?.file;
              const imageAltCopy =
                content.fields?.imageAltCopy || 'promotional banner';
              const link1 = content.fields?.link1 || '';
              const link2 = content.fields?.link2 || '';
              const linkUrl: string =
                link1?.content?.[0]?.content?.[1]?.data.uri || '';
              const contentHeadline = content.fields?.headline || '';
              const contentSubCopy = content.fields?.subcopy || '';

              return (
                <div
                  key={`${contentHeadline}-${idx}`}
                  className={classNames(
                    isTwoUp && 'col-6',
                    isThreeUp && 'col-4',
                    'col-xs-12',
                  )}
                >
                  <RenderImage
                    imageAltCopy={imageAltCopy}
                    imageLink={imageDetails}
                    index={index}
                    linkUrl={linkUrl}
                    targetAttributeValue={targetAttributeValue}
                  />
                  <div
                    className={classNames(
                      textAlignment,
                      'copyBlock',
                      isCenterAligned && 'noPaddingRight',
                    )}
                  >
                    {contentHeadline && (
                      <h3 className="subHeadln">{contentHeadline}</h3>
                    )}

                    {contentSubCopy && (
                      <p
                        className="subCopy"
                        data-testid="contentTileSubheader"
                      >
                        {contentSubCopy}
                      </p>
                    )}
                    <div className={`links ${textAlignment}`}>
                      {link1 &&
                        documentToReactComponents(
                          link1,
                          options as Options,
                        )}
                      {link2 &&
                        documentToReactComponents(
                          link2,
                          options as Options,
                        )}
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentTiles;
