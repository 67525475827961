import React from 'react';
import { LinkButton } from '@fashionphile/component-library';
import { convertButtonString } from '../../../utilities/helpers';
import { EditorialCopyBlockData } from '../../../types/DynamicLandingPageReducer';
import { renderRichText } from '../utils';

const EditorialCopyBlock = ({
  data,
  index,
}: {
  data: EditorialCopyBlockData;
  index: number;
}): React.ReactElement => {
  const eyebrowCopy = data.fields?.eyebrowCopy;
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const cta = data.fields.cta?.fields;
  const align = (data.fields.textAlignment || '').toLowerCase();
  const editorialContent = data.fields.editorialContent || '';
  const headlineStyle = (
    data.fields.headlineStyle || ''
  ).toLowerCase();
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="row editorialCopyBlock">
        <div className="col-1 placeholder" />
        <div className="col-10 col-xs-12">
          <div className={`pageContent ${align}`}>
            <>
              <p className="eyebrow">{eyebrowCopy}</p>
              {editorialContent &&
                renderRichText(
                  editorialContent,
                  '_self',
                  headlineStyle,
                )}
            </>
            <>
              {cta && (
                <div className="buttonContainer">
                  <LinkButton
                    target={targetAttributeValue}
                    content={cta.textToDisplay}
                    size="lg"
                    variant={convertButtonString(cta.color)}
                    ariaLabel="button"
                    href={cta.linkUrl}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-1 placeholder" />
      </div>
    </div>
  );
};

export default EditorialCopyBlock;
