import React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { TextTileData } from '../../../types/DynamicLandingPageReducer';

const TextTile = ({
  data,
  index,
}: {
  data: TextTileData;
  index: number;
}): React.ReactElement => {
  const arrangement = data.fields.arrangement || ''; // 2-up
  const isTwoUp = arrangement.includes('2');
  const isThreeUp = arrangement.includes('3');
  const align = (data.fields.textAlignment || '').toLowerCase();
  const textTileLinks = data.fields.textTileLinks || [];
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className="hyperLink"
          href={node.data.uri}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="textTileContainer">
        <div className="row">
          {textTileLinks.map((textTile, idx: number) => {
            const headline = textTile.fields.headline || '';
            const subCopy = textTile.fields.subcopy || '';
            const { link } = textTile.fields;

            return (
              <div
                key={idx}
                className={classNames(
                  align,
                  isTwoUp && 'col-6',
                  isThreeUp && 'col-4',
                  'col-xs-12',
                )}
              >
                <h3 className="subHeadln">{headline}</h3>

                <p className={subCopy} data-testid="hero-subheader">
                  {subCopy}
                </p>

                <div className={`links ${align}`}>
                  {link &&
                    documentToReactComponents(
                      link,
                      options as Options,
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TextTile;
