import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { LinkButton } from '@fashionphile/component-library';
import {
  convertButtonString,
  contentfulSrcSet,
} from '../../../utilities/helpers';
import { useMediaQuery } from '../../../utilities/useMediaQuery';
import { MOBILE_PORTRAIT_BREAKPOINT } from '../../../utilities/constants';
import {
  ImageBannerData,
  ImageData,
  TypeCopyBlock,
} from '../../../types/DynamicLandingPageReducer';

const RenderImage = ({
  desktopImage,
  imageAltCopy,
  index,
  linkUrl,
  mobileImage,
  targetAttributeValue,
}: ImageData): React.ReactElement => {
  // remove trailing forward slash so that I can grab the last bit of the url to make a title
  if (linkUrl[linkUrl.length - 1] === '/') {
    linkUrl = linkUrl.slice(0, linkUrl.length - 1);
  }
  const linkTitle = linkUrl.split('/')[linkUrl.split('/').length - 1];
  const mobileImageUrl = mobileImage?.fields?.file?.url || '';
  const desktopImageUrl = desktopImage?.fields?.file?.url || '';

  if (linkUrl !== '') {
    return (
      <Link
        href={linkUrl}
        passHref
        target={targetAttributeValue}
        title={`${linkTitle} link`}
      >
        <img
          src={`https://${desktopImageUrl}`}
          srcSet={contentfulSrcSet(desktopImageUrl, {
            sm: 577,
            md: 769,
            lg: 993,
            xl: 1281,
            xxl: 1600,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
          className="desktopOnly"
        />
        <img
          src={`https://${mobileImageUrl}`}
          srcSet={contentfulSrcSet(mobileImageUrl, {
            sm: 577,
            md: 769,
            lg: 993,
            xl: 1281,
            xxl: 1600,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
          className="mobileOnly"
        />
      </Link>
    );
  }
  return (
    <>
      <img
        src={`https://${desktopImageUrl}`}
        srcSet={contentfulSrcSet(desktopImageUrl, {
          sm: 577,
          md: 769,
          lg: 993,
          xl: 1281,
          xxl: 1600,
        })}
        sizes="100vw"
        alt={imageAltCopy}
        loading={index > 0 ? 'lazy' : undefined}
        tabIndex={0}
        className="desktopImage desktopOnly"
      />
      <img
        src={`https://${mobileImageUrl}`}
        srcSet={contentfulSrcSet(mobileImageUrl, {
          sm: 577,
          md: 769,
          lg: 993,
          xl: 1281,
          xxl: 1600,
        })}
        sizes="100vw"
        alt={imageAltCopy}
        loading={index > 0 ? 'lazy' : undefined}
        tabIndex={0}
        className="mobileImage mobileOnly"
      />
    </>
  );
};

const RenderCopyBlock = ({
  targetAttributeValue,
  align,
  desktopTextColor,
  headlineStyle,
  headline,
  subcopy,
  cta,
  linkUrl,
}: TypeCopyBlock): React.ReactElement => {
  const MIN_COLUMN_SIZE = 1;
  const HALF_COLUMN_SIZE = 3;
  const MAX_COLUMN_SIZE = 5;
  let leftColumnPlaceholder = MIN_COLUMN_SIZE;
  let rightColumnPlaceHolder = MAX_COLUMN_SIZE;
  if (align === 'center') {
    leftColumnPlaceholder = HALF_COLUMN_SIZE;
    rightColumnPlaceHolder = HALF_COLUMN_SIZE;
  }
  if (align === 'right') {
    leftColumnPlaceholder = MAX_COLUMN_SIZE;
    rightColumnPlaceHolder = MIN_COLUMN_SIZE;
  }

  const isMobilePortrait = useMediaQuery(
    `(max-width: ${MOBILE_PORTRAIT_BREAKPOINT}px)`,
  );

  return (
    <div className="row copyBlockContainer">
      {!isMobilePortrait && (
        <div className={`col-${leftColumnPlaceholder}`} />
      )}
      <div
        className={classNames(
          'copyBlock',
          `${desktopTextColor}-desktopText black-mobileText`,
          'col-6',
          'col-xs-12',
          align,
        )}
      >
        {headline && (
          <h2 className={headlineStyle} data-testid="hero-header">
            {headline}
          </h2>
        )}
        {subcopy && (
          <p data-testid="hero-subheader" className="subhed">
            {subcopy}
          </p>
        )}
        {cta && (
          <div className="button">
            <LinkButton
              target={targetAttributeValue}
              content={cta?.textToDisplay}
              size="lg"
              variant={convertButtonString(cta?.color)}
              ariaLabel="button"
              href={linkUrl}
              tabIndex={0}
            />
          </div>
        )}
      </div>
      {!isMobilePortrait && (
        <div className={`col-${rightColumnPlaceHolder}`} />
      )}
    </div>
  );
};

const ImageBanner = ({
  data,
  index,
}: {
  data: ImageBannerData;
  index: number;
}): React.ReactElement => {
  const cta = data.fields.cta?.fields;
  const linkUrl = cta?.linkUrl || '';
  const targetAttributeValue = data.fields.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const imageAltCopy =
    data.fields.imageAltCopy || 'promotional banner';
  const headline = data.fields.headline || '';
  const subcopy = data.fields.subcopy || '';
  const headlineStyle = (
    data.fields.headlineStyle || ''
  ).toLowerCase();
  const align = (data.fields.textAlignment || '').toLowerCase();
  const desktopTextColor = (
    data.fields.textColor || 'black'
  ).toLowerCase();
  const isImageOnly =
    align.includes('none') ||
    headlineStyle.includes('none') ||
    desktopTextColor.includes('none');
  const mobileImage = data.fields?.mobileImage;
  const desktopImage = data.fields?.desktopImage;
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="imageBannerContainer">
        <div className="imageStyles">
          <RenderImage
            desktopImage={desktopImage}
            imageAltCopy={imageAltCopy}
            index={index}
            linkUrl={linkUrl}
            mobileImage={mobileImage}
            targetAttributeValue={targetAttributeValue}
          />
        </div>
        {!isImageOnly && (
          <RenderCopyBlock
            targetAttributeValue={targetAttributeValue}
            align={align}
            desktopTextColor={desktopTextColor}
            headlineStyle={headlineStyle}
            headline={headline}
            subcopy={subcopy}
            cta={cta}
            linkUrl={linkUrl}
          />
        )}
      </div>
    </div>
  );
};

export default ImageBanner;
