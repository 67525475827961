import React from 'react';
import Link from 'next/link';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node } from '@contentful/rich-text-types';
import { contentfulSrcSet } from '../../../utilities/helpers';
import {
  HorizontalImageData,
  ImageData,
} from '../../../types/DynamicLandingPageReducer';

const RenderImage = ({
  imageAltCopy,
  imageLink,
  index,
  linkUrl = '',
  targetAttributeValue,
}: ImageData): React.ReactElement => {
  // remove trailing forward slash so that I can grab the last bit of the url to make a title
  if (linkUrl[linkUrl.length - 1] === '/') {
    linkUrl = linkUrl.slice(0, linkUrl.length - 1);
  }
  const linkTitle = linkUrl.split('/')[linkUrl.split('/').length - 1];

  if (linkUrl !== '') {
    return (
      <Link
        href={linkUrl}
        passHref
        target={targetAttributeValue}
        title={`${linkTitle} link`}
      >
        <img
          src={`https://${imageLink?.url}`}
          srcSet={contentfulSrcSet(imageLink?.url || '', {
            sm: 529,
            md: 349,
            lg: 453,
            xl: 596,
            xxl: 596,
          })}
          sizes="100vw"
          alt={imageAltCopy}
          loading={index > 0 ? 'lazy' : undefined}
        />
      </Link>
    );
  }
  return (
    <img
      src={`https://${imageLink?.url}`}
      srcSet={contentfulSrcSet(imageLink?.url || '', {
        sm: 529,
        md: 349,
        lg: 453,
        xl: 596,
        xxl: 596,
      })}
      sizes="100vw"
      alt={imageAltCopy}
      loading={index > 0 ? 'lazy' : undefined}
      tabIndex={0}
    />
  );
};

const HorizontalImage = ({
  data,
  index,
}: {
  data: HorizontalImageData;
  index: number;
}): React.ReactElement => {
  const headline = data.fields.headline || '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const textAlignment = (
    data.fields.textAlignment || ''
  ).toLowerCase();

  const giftGuideImage = data.fields.giftGuideImage || [];
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className="hyperLink"
          href={node.data.uri}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={-1}
        >
          {children}
        </a>
      ),
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="horizontalImageContainer">
        <div className="row">
          {headline && <h2 className="col-12 headln">{headline}</h2>}
        </div>
        <div className="row">
          {giftGuideImage.map((content, idx) => {
            const imageDetails =
              content.fields?.tileImage?.fields?.file;
            const imageAltCopy =
              content.fields?.tileImage?.imageAltCopy ||
              'promotional banner';
            const link1 = content.fields?.link1 || '';
            const linkUrl =
              link1?.content?.[0]?.content?.[1]?.data?.uri || '';

            return (
              <div
                key={`${imageAltCopy}-${idx}`}
                className="col-6 col-xs-12"
              >
                <div className="imageStyles">
                  <RenderImage
                    imageAltCopy={imageAltCopy}
                    imageLink={imageDetails}
                    index={index}
                    linkUrl={linkUrl}
                    targetAttributeValue={targetAttributeValue}
                  />
                </div>
                <div className={`links ${textAlignment}`}>
                  {link1 &&
                    documentToReactComponents(
                      link1,
                      options as Options,
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HorizontalImage;
