import React from 'react';
import { ThumbnailHighlightsData } from '../../../types/DynamicLandingPageReducer';
import { renderRichText } from '../utils';

const ThumbnailHighlights = ({
  data,
  index,
}: {
  data: ThumbnailHighlightsData;
  index: number;
}): React.ReactElement => {
  const topHeadline = data.fields?.headline || '';
  const modulesHeadline = data.fields?.subHeadline || '';
  const backgroundColor = `${(
    data.fields?.backgroundColor || 'white'
  ).toLowerCase()}-bg`;
  const imageAlignment = (
    data.fields?.imageAlignment || ''
  ).toLowerCase();
  const isImageAlignedLeft = imageAlignment === 'left';
  const eyebrowCopyAlignment = (
    data.fields?.eyebrowCopyAlignment || 'center'
  ).toLowerCase();
  const tiles = data.fields?.tiles || [];
  const eyebrowCopy = data.fields?.eyebrowCopy || '';
  const eyebrowCopyBottom = data.fields?.eyebrowCopyBottom || '';
  const imageAltCopy = data.fields?.imageAltCopy || '';
  const imageUrl = data.fields?.image?.fields?.file?.url || '';
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';
  let tileTargetAttributeValue = '';

  const modulesColumn = (
    <div
      className={`${
        !imageUrl ? 'col-12' : 'col-6'
      } col-xs-12 modulesContainer`}
    >
      {eyebrowCopyBottom && (
        <div className="row">
          <p className="col-12 eyebrowBottom">{eyebrowCopyBottom}</p>
        </div>
      )}
      {modulesHeadline && (
        <div className="row">
          <h2 className="col-12 subHeadln">{modulesHeadline}</h2>
        </div>
      )}
      {tiles.map((item, idx) => {
        const tileHeadline = item.fields?.subheadline || '';
        const tileSubCopyRichText =
          item.fields?.subCopyRichText || '';
        const tileThumbnailAltCopy =
          item.fields?.thumbnailAltCopy || '';
        const tileThumbnailUrl =
          item.fields?.thumbnail?.fields.file.url || null;
        const tileLink = item.fields?.link || '';
        tileTargetAttributeValue = item.fields?.linkOutToAnotherTab
          ? '_blank'
          : '_self';

        return (
          <div key={`${tileHeadline}-${idx}`} className="row tileRow">
            {tileThumbnailUrl && (
              <div className="col-3">
                <img
                  src={tileThumbnailUrl}
                  alt={tileThumbnailAltCopy}
                  data-testid="thumbnailTileImage"
                />
              </div>
            )}
            <div
              className={`${tileThumbnailUrl ? 'col-9' : 'col-12'}`}
            >
              {tileHeadline && <h3>{tileHeadline}</h3>}
              {tileSubCopyRichText && (
                <div>
                  {tileSubCopyRichText &&
                    renderRichText(
                      tileSubCopyRichText,
                      tileTargetAttributeValue,
                    )}
                </div>
              )}
              {tileLink && (
                <div className="links">
                  {tileLink &&
                    renderRichText(
                      tileLink,
                      tileTargetAttributeValue,
                    )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  const imageColumn = (
    <>
      {imageUrl && (
        <div
          className={`col-6 col-xs-12 imageContainer ${
            isImageAlignedLeft
              ? 'imageLeftStyles'
              : 'imageRightStyles'
          }`}
        >
          <img
            src={imageUrl}
            alt={imageAltCopy}
            data-testid="thumbnailLargeImage"
          />
        </div>
      )}
    </>
  );

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div
        className={`thumbnailHighlightsContainer ${backgroundColor}`}
        data-testid="thumbnailHighlightsContainer"
      >
        {eyebrowCopy && (
          <div className="row">
            <p
              className={`col-12 eyebrowTop ${eyebrowCopyAlignment}`}
            >
              {eyebrowCopy}
            </p>
          </div>
        )}
        {topHeadline && (
          <div className="row">
            <h1 className="col-12 headln"> {topHeadline}</h1>
          </div>
        )}
        <div className="row">
          {isImageAlignedLeft ? (
            <>
              {imageColumn}
              {modulesColumn}
            </>
          ) : (
            <>
              {modulesColumn}
              {imageColumn}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThumbnailHighlights;
