import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [isMatching, setMatching] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== isMatching) {
      setMatching(media.matches);
    }
    const listener = (): void => {
      setMatching(media.matches);
    };
    media.addListener(listener);
    return (): void => media.removeListener(listener);
  }, [isMatching, query]);

  return isMatching;
};
